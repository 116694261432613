import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Orders from "./pages/Orders";
import Layout from "./components/Layout";
import AuthPage from "./pages/Auth";
import {CheckStaffPermission, IfAuthed, RequireAuth} from "./helpers/helpers";
import OrderView from "./pages/OrderView";
import Notifications from "./pages/Notifications";
import NotificationView from "./pages/NotificationView";
import Personal from "./pages/Personal";
import Home from "./pages/Home";
import Customers from "./pages/Customers";
import CustomersView from "./pages/CustomersView";
import NotificationAdd from "./pages/NotificationAdd";
import Ransom from "./pages/Ransom";
import RansomAdd from "./pages/RansomAdd";
import RansomEdit from "./pages/RansomEdit";
import LostOrders from "./pages/LostOrders";
import Warehouse from "./pages/Warehouse";
import WarehouseAdd from "./pages/WarehouseAdd";
import Sorting from "./pages/Sorting";
import SortingAdd from "./pages/SortingAdd";
import Issue from "./pages/Issue";
import BoxOffice from "./pages/BoxOffice";
import PaymentHistory from "./pages/PaymentHistory";
import ShipmentsHistory from "./pages/ShipmentsHistory";
import ShipmentsHistoryOrders from "./pages/ShipmentsHistoryOrders";
import BalanceAddHistory from "./pages/BalanceAddHistory";
import TotalWeightOfOrders from "./pages/TotalWeightOfOrders";
import TotalWeightOfOrdersGroups from "./pages/TotalWeightOfOrdersGroups";


function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path='/*' element={
                <RequireAuth>
                  <Layout/>
                </RequireAuth>
              }>
                  <Route index element={<Home />}/>
                  <Route path='orders' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                          'Складовщик',
                      ]} children={<Orders/>}/>
                  }/>
                  <Route path='orderView/:id' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                          'Складовщик',
                      ]} children={<OrderView/>}/>
                  }/>
                  <Route path='notifications' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<Notifications/>}/>
                  }/>
                  <Route path='notificationView/:id' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<NotificationView/>}/>
                  }/>
                  <Route path='notificationAdd' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<NotificationAdd/>}/>
                  }/>
                  <Route path='personal' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                      ]} children={<Personal/>}/>
                  }/>
                  <Route path='customers' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                          'Кассир',
                      ]} children={<Customers/>}/>
                  }/>
                  <Route path='customersView/:id' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                          'Кассир',
                      ]} children={<CustomersView/>}/>
                  }/>
                  <Route path='ransom' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<Ransom/>}/>
                  }/>
                  <Route path='ransomAdd' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<RansomAdd/>}/>
                  }/>
                  <Route path='ransomEdit/:id' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<RansomEdit/>}/>
                  }/>
                  <Route path='sorting' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Сортировщик',
                      ]} children={<Sorting/>}/>
                  }/>
                  <Route path='sortingAdd' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Сортировщик',
                      ]} children={<SortingAdd/>}/>
                  }/>
                  <Route path='warehouse' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Складовщик',
                      ]} children={<Warehouse/>}/>
                  }/>
                  <Route path='warehouseAdd' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Складовщик',
                      ]} children={<WarehouseAdd/>}/>
                  }/>
                  <Route path='lostOrders' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<LostOrders/>}/>
                  }/>
                  <Route path='issue' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Сортировщик',
                      ]} children={<Issue/>}/>
                  }/>
                  <Route path='boxOffice' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Кассир',
                      ]} children={<BoxOffice/>}/>
                  }/>
                  <Route path='paymentHistory' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                      ]} children={<PaymentHistory/>}/>
                  }/>
                  <Route path='balanceAddHistory' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Менеджер',
                      ]} children={<BalanceAddHistory/>}/>
                  }/>
                  <Route path='totalWeightOfOrders' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                      ]} children={<TotalWeightOfOrders/>}/>
                  }/>
                  <Route path='totalWeightOfOrders/:id' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Складовщик',
                      ]} children={<TotalWeightOfOrdersGroups/>}/>
                  }/>
                  <Route path='shipmentHistory' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Складовщик',
                      ]} children={<ShipmentsHistory/>}/>
                  }/>
                  <Route path='shipmentHistoryOrders/:id' element={
                      <CheckStaffPermission listOfStaffs={[
                          'Администратор',
                          'Складовщик',
                      ]} children={<ShipmentsHistoryOrders/>}/>
                  }/>
              </Route>

              <Route path='auth' element={
                <IfAuthed>
                  <AuthPage/>
                </IfAuthed>
              }/>
            </Routes>

      </BrowserRouter>
  );
}

export default App;
