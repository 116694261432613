import {$axios} from "../http";

export const AnalyticsService = {
    async GetAnalytics(start_date: string, end_date: string, flag: string) {
        return await $axios.post('/admin/analytics/',{
            start_date: start_date,
            end_date: end_date,
            flag: flag,
        })
    }
}