import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const IssueService = {
    GetIssueCheque(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/issue/search/' + CreateCleanSearchParams(searchParamsObj))
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async SetChequeIssued(id: string | undefined) {
        return await $axios.put('/admin/issue/' + `${id}/`, {issued: true})
    }
}