import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon,
} from "../helpers/common";
import moment from "moment/moment";
import {HistoryService} from "../services/HistoryService";
import {Pagination} from "@mui/material";

function BalanceAddHistory() {
    const [filterValue, setFilterValue] = React.useState<any>({
        start_created_at: '',
        end_created_at: '',
        count: 1,
        page: 1,
        search: '',
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID', width: '80px', hide:true},
        { field: 'user_id', headerName: 'Код клиента', width: '80px'},
        { field: 'created_at', headerName: 'Дата', width: '100px'},
        { field: 'full_name', headerName: 'ФИО'},
        { field: 'amount', headerName: 'Сумма', width: '150px'},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])

    const paymentList = HistoryService.GetBalanceAddHistory(filterValue)
    useEffect(()=>{
        if(!paymentList.loading && !paymentList.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: paymentList.result?.data.total_pages,
                page: paymentList.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i< paymentList.result?.data.results.length;i++){
                arr[i] = {
                    id: paymentList.result?.data.results[i].id,
                    user_id: paymentList.result?.data.results[i].user.code,
                    created_at: moment(paymentList.result?.data.results[i].created_at).format('DD.MM.YYYY').toString(),
                    full_name: paymentList.result?.data.results[i].user.full_name,
                    amount: `${paymentList.result?.data.results[i].deposit_amount} сом`,
                }
            }
            setMainTableData(arr)
        }
    },[paymentList.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>История пополнений</h1>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Начало даты'
                            value={filterValue.start_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                start_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Конец даты'
                            value={filterValue.end_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                end_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите код клиента'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={paymentList.loading}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default BalanceAddHistory;

