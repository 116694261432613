import {$axios} from "../http";
import {useAsync} from "react-async-hook";

interface TariffI {
    id: string,
    address1: string,
    address2: string,
    city: string,
    zip: string,
    phone: string,
    prefix: string,
    delivery_period: string,
    tariff: string,
    tariff_vip: string,
}

export const TariffService = {
    GetTariff(updateRequest?: boolean) {
        return useAsync(async () => {
            return await $axios.get('/addresses/')
        }, [updateRequest])
    },
    async UpdateTariff(tariff: TariffI) {
        return await $axios.put('/addresses/' + `${tariff.id}/`, tariff)
    }
}