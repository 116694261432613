import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {CustomPagination, CustomTable, OrderDeleteIcon} from "../helpers/common";
import moment from "moment/moment";
import parse from 'html-react-parser'
import {NotificationService} from "../services/NotificationService";
import {Pagination} from "@mui/material";

function Notifications() {
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
        count: 1,
        update_request: false,
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID', width: '100px'},
        { field: 'title', headerName: 'Название', renderCell: (params: any) => {
                return (
                    <div className="notifications__row flex flex-col justify-center items-start">
                        <div className='flex justify-start items-center gap-3.5' style={{width: '690px', overflow:"hidden"}}>
                            <p style={params.read ? {color:'rgba(87,88,90,0.8)'} : {color: '#000032'}}>{params.title}</p>{parse(params.message)}
                        </div>
                        <div className='flex justify-start items-center gap-3.5'>
                            <span>{params.created_time} </span>
                        </div>
                    </div>
                )
            } },
        { field: 'created_at', headerName: 'Дата', width: '110px'},
        { field: 'status', headerName: '', width: '60px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-start items-center gap-5">
                        <div className='page-table__icon-button' onClick={()=>{
                            NotificationService.DeleteNotification(params.id).then(()=>{
                                setFilterValue({
                                    ...filterValue,
                                    update_request:!filterValue.update_request
                                })
                            })
                        }}>
                            <OrderDeleteIcon/>
                        </div>
                    </div>
                )
            }},
    ];

    const [selectedRowsId, setSelectedRowsId] = useState<any>([])
    const [mainTableData, setMainTableData] = useState<any>([])

    const notifications = NotificationService.GetNotifications(filterValue)

    useEffect(()=>{
        if(!notifications.loading && !notifications.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: notifications.result?.data.total_pages,
                page: notifications.result?.data.current_page,
            }))
            const notificationsArray = []
            for (let i=0;i<notifications.result?.data.results.length;i++){
                notificationsArray[i] = {
                    id: notifications.result?.data.results[i].id,
                    title: notifications.result?.data.results[i].title,
                    message: notifications.result?.data.results[i].message,
                    created_time: moment(notifications.result?.data.results[i].created_at).format('hh:mm').toString(),
                    created_at: moment(notifications.result?.data.results[i].created_at).format('DD.MM.YYYY').toString(),
                    read: notifications.result?.data.results[i].read,
                }
            }
            setMainTableData(notificationsArray)
        }
    },[notifications.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Уведомления </h1>
                    <div className='submit-button_blue h-10'
                         onClick={()=>{navigate('/notificationAdd')}}
                    >
                        Добавить уведомление
                    </div>
                </div>
                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={true}
                    selectedRowsId={selectedRowsId}
                    loading={notifications.loading}
                    onSelectionModelChange={(selectionModel: [])=>setSelectedRowsId(selectionModel)}
                    onRowDoubleClick={(params: any)=>{ navigate(`/notificationView/${params.id}`)}}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <div className='w-full flex justify-between items-center mt-2.5 mr-2.5'>
                                <div>

                                </div>
                                <div className='flex justify-center items-center gap-5'>
                                    {selectedRowsId.length > 0 &&
                                        <span className='selected-orders-count'>Выбрано {selectedRowsId.length}</span>
                                    }
                                    <button disabled={selectedRowsId.length === 0}
                                            className='submit-button_white submit-button_white_small h-7'
                                            onClick={()=>{
                                                NotificationService.DeleteSelectedNotifications(selectedRowsId).then(()=>{
                                                    setFilterValue({
                                                        ...filterValue,
                                                        update_request: !filterValue.update_request,
                                                    })
                                                    setSelectedRowsId([])
                                                })
                                            }}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            </div>
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default Notifications;

