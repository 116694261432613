import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomPagination,
    CustomTable,
} from "../helpers/common";
import {useNavigate} from "react-router-dom";
import {OrdersService} from "../services/OrdersService";
import {Pagination} from "@mui/material";

function TotalWeightOfOrders() {
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        start_created_at: '',
        end_created_at: '',
        page: 1,
        count: 1,
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID', width: '80px'},
        { field: 'created_at', headerName: 'Дата', width: '100px'},
        { field: 'total_weight', headerName: 'Общий вес'},
        { field: 'total_sum', headerName: 'Сумма'},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])
    const shipmentHistoryList = OrdersService.GetTotalWeightOfOrders(filterValue)
    useEffect(()=>{
        if(!shipmentHistoryList.loading && !shipmentHistoryList.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: shipmentHistoryList.result?.data.total_pages,
                page: shipmentHistoryList.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i< shipmentHistoryList.result?.data.results.length;i++){
                arr[i] = {
                    id: shipmentHistoryList.result?.data.results[i].id,
                    created_at: shipmentHistoryList.result?.data.results[i].name,
                    total_weight: `${shipmentHistoryList.result?.data.results[i].total_weight} кг`,
                    total_sum: `${shipmentHistoryList.result?.data.results[i].total_sum} сом`,
                }
            }
            setMainTableData(arr)
        }
    },[shipmentHistoryList.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Прибывшие посылки </h1>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Начало даты'
                            value={filterValue.start_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                start_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Конец даты'
                            value={filterValue.end_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                end_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                    </div>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={shipmentHistoryList.loading}
                    onRowDoubleClick={(params)=>{navigate(`/totalWeightOfOrders/${params.id}`)}}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default TotalWeightOfOrders;

