import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

interface paymentValuesT {
    amount: string,
    paid_by_balance: string,
    discount: string,
    payment_method: string,
}

export const BoxOfficeService = {
    GetCashGroups(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/cash/groups/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    GetPayments() {
        return useAsync(async () => {
            return await $axios.get('/admin/payment/types')
        }, [])
    },
    async CalculateCashOrders(group: number) {
        return await $axios.post('/admin/cash/orders/calculate/amount/', {orders_group_id: group})
    },

    async CreateCashTransaction(paymentValues: paymentValuesT, orders_group: any) {
        return await $axios.post('/admin/cash/transaction/create/', {
            orders_group: parseInt(orders_group),
            payment_method: parseInt(paymentValues.payment_method),
            amount: parseInt(paymentValues.amount),
            paid_by_balance: parseInt(paymentValues.paid_by_balance),
            discount: parseInt(paymentValues.discount),
        })
    },
    async ChangeTotalWeight(data: any) {
        return await $axios.put('/admin/cash/groups/' + `${data.id}/`, {total_weight: data.weight})
    },

}