import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const ClientsService = {
    async GetClient(id: string | undefined) {
        return await $axios.get('/admin/clients/' + `${id}`)
    },
    GetCustomerByUseAsync(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get('/admin/clients/' + id);
        }, [`${id}`])
    },
    GetClientsList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/clients/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async DeleteClient(id: string) {
        return await $axios.delete('/admin/clients/' + `${id}/`)
    },
    async CreateClient(data: any) {
        const newData = data
        let form_data = new FormData()
        if (newData.passport_back_side.name === '') {
            delete newData.passport_back_side
        }
        if (newData.passport_front_side.name === '') {
            delete newData.passport_front_side
        }
        for (let key in newData) {
            if (newData[key] === '') {
                delete newData[key]
            }
            else{
                form_data.append(key, newData[key]);
            }
        }
        return await $axios.post('/admin/clients/create/', form_data)
    },

    async UpdateClient(data: any, id: string) {
        const newData = data
        let form_data = new FormData()
        if (newData.passport_back_side.name === '') {
            delete newData.passport_back_side
        }
        if (newData.passport_front_side.name === '') {
            delete newData.passport_front_side
        }
        for (let key in newData) {
            if (newData[key] === '') {
                delete newData[key]
            }
            else{
                form_data.append(key, newData[key]);
            }
        }
        return await $axios.put('/admin/clients/update/' + `${id}/`, form_data)
    },

    async SetClientVerified(
        id: string | undefined,
        is_verified: boolean,
        is_vip_user: boolean,
    ) {
        return await $axios.put('/admin/clients/' + `${id}/`, {
            is_verified: is_verified,
            is_vip_user: is_vip_user
        })
    },
    async UpdateUserBalance(user_id: any, value: string) {
        return await $axios.post('/admin/clients/balance/replenish/', {
            user_id: parseInt(user_id),
            deposit_amount: parseInt(value)
        })
    },

    GetCustomersListBySearch(user_id: string) {
        return useAsync(async () => {
            return await $axios.get('/admin/search/client/' + `?search=${user_id}`);
        }, [user_id])
    },

    GetCustomerBySearch(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/search/client/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
}