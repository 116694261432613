import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon, OrderDeleteIcon,
} from "../helpers/common";
import moment from "moment/moment";
import {OrdersService} from "../services/OrdersService";
import {Pagination} from "@mui/material";

function Warehouse() {
    const navigate = useNavigate()
    const [validation, setValidation] = useState({
        requestIsSend: false,
    })
    const [filterValue, setFilterValue] = React.useState<any>({
        start_created_at: '',
        end_created_at: '',
        page: 1,
        count: 1,
        search: '',
        update_request: false,
        requestIsSend: false,
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID посылки', hide: true},
        { field: 'code', headerName: 'Код клиента',  width: '80px'},
        { field: 'full_name', headerName: 'ФИО'},
        { field: 'tracking_number', headerName: 'Трек-код',},
        { field: 'created_at', headerName: 'Дата', width: '100px'},
        { field: 'comment', headerName: 'Комментарий'},
        { field: 'delete', headerName: '', renderCell:(params: any)=>(
                <div className="flex justify-start items-center gap-5">
                    <div className='page-table__icon-button' onClick={()=>{
                        OrdersService.DeleteOrderFromWarehouse(params.id).then(()=>{
                            setFilterValue({
                                ...filterValue,
                                update_request:!filterValue.update_request
                            })
                        })
                    }}>
                        <OrderDeleteIcon/>
                    </div>
                </div>
            )},
    ];
    const [selectedRowsId, setSelectedRowsId] = useState<any>([])
    const [mainTableData, setMainTableData] = useState<any>([])

    const warehouses = OrdersService.GetOrdersInWarehouse(filterValue)

    const sendSelectedOrders = () =>{
        setValidation({
            ...validation,
            requestIsSend: true,
        })
        OrdersService.WarehouseOrdersSend(selectedRowsId).then((res)=>{
            setFilterValue({
                ...filterValue,
                update_request: !filterValue.update_request,
            })
            setSelectedRowsId([])
            setValidation({
                ...validation,
                requestIsSend: false,
            })
            window.open(res.data.excel_url)
        }).catch((err)=>{
            console.log(err)
            setValidation({
                ...validation,
                requestIsSend: false,
            })
        })
    }

    useEffect(()=>{
        if(!warehouses.loading && !warehouses.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: warehouses.result?.data.total_pages,
                page: warehouses.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i< warehouses.result?.data.results.length;i++){
                arr[i] = {
                    id: warehouses.result?.data.results[i].id,
                    full_name: warehouses.result?.data.results[i].user !== null ? warehouses.result?.data.results[i].user.full_name : warehouses.result?.data.results[i].attribute,
                    code: warehouses.result?.data.results[i].user !== null ? warehouses.result?.data.results[i].user.code : '',
                    tracking_number: warehouses.result?.data.results[i].tracking_number,
                    created_at: moment(warehouses.result?.data.results[i].created_at).format('DD.MM.YYYY').toString(),
                    comment: warehouses.result?.data.results[i].comment,
                }
            }
            setMainTableData(arr)
        }
    },[warehouses.loading])

    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Склад</h1>
                    <div className='submit-button_blue h-10'
                         onClick={()=>{navigate('/warehouseAdd')}}
                    >
                        Добавить посылку
                    </div>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Начало даты'
                            value={filterValue.start_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                start_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                        <CustomInput
                            className='filter-input'
                            type='date'
                            placeholder='Конец даты'
                            value={filterValue.end_created_at}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                end_created_at: event.target.value,
                                page: 1,
                            })}}
                        />
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите трек номер'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={true}
                    multipleSelect={true}
                    selectedRowsId={selectedRowsId}
                    loading={warehouses.loading}
                    onRowDoubleClick={(params: any)=>{ navigate(`/orderView/${params.id}`)}}
                    onSelectionModelChange={(selectionModel: [])=>{
                        console.log(selectionModel)
                        setSelectedRowsId(selectionModel)
                    }}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <div className='w-full flex justify-between items-center mt-2.5 mr-2.5'>
                                <div>

                                </div>
                                <div className='flex justify-center items-center gap-5'>
                                    {selectedRowsId.length > 0 &&
                                        <span className='selected-orders-count'>Выбрано {selectedRowsId.length}</span>
                                    }

                                    <button disabled={selectedRowsId.length === 0 || validation.requestIsSend}
                                            className='submit-button_blue submit-button_blue_small h-7'
                                            onClick={sendSelectedOrders}
                                    >
                                        {validation.requestIsSend
                                            ? <div style={{width: '28px', height: '28px'}} className="loader"></div>
                                            : <span>Отправить посылки</span>
                                        }
                                    </button>
                                </div>
                            </div>
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default Warehouse;

