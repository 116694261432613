import React, {useEffect, useState} from 'react';
import {Autocomplete, TextField} from "@mui/material";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {Link, useNavigate} from "react-router-dom";
import {ClientsService} from "../services/ClientsService";
import {NotificationService} from "../services/NotificationService";

function NotificationAdd() {
    const navigate = useNavigate()
    const [validation, setValidation] = useState<any>({
        authRequestIsSent: false,
    })
    const [notificationInfo, setNotificationInfo] = useState<any>({
        title: '',
        message: '',
        users: [],
        send_all: true,
    })
    const [userSearchName, setUserSearchName] = useState('')
    const [customers, setCustomers] = useState<any>([])
    const customersList = ClientsService.GetCustomersListBySearch(userSearchName)
    useEffect(()=>{
        if(!customersList.loading && !customersList.error){
            setCustomers(customersList.result?.data)
        }
    },[customersList.loading])
    const handleFormSubmit = () =>{
        setValidation({
            ...validation,
            authRequestIsSent: true,
        })
        NotificationService.CreateNotification(notificationInfo).then(()=>{
            navigate({
                pathname: '/notifications',
            })
        })
    }
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    }
    const  formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Новое уведомление</h1>
                </div>
                <form onSubmit={(event)=>{
                    event.preventDefault()
                    handleFormSubmit()
                }}
                    className='notification-view__container w-full flex flex-col justify-start items-center py-5 px-8'>
                    <div className='w-full flex gap-10 mb-5'>
                        <div className='w-full'>
                            <Autocomplete
                                freeSolo
                                multiple
                                disabled={notificationInfo.send_all}
                                isOptionEqualToValue={(option: any, value) => option.full_name === value.full_name}
                                getOptionLabel={(option:any) => option.full_name}
                                options={customers}
                                loading={customersList.loading}
                                onChange={(event: any, newValue: any,) => {
                                    setNotificationInfo({
                                        ...notificationInfo,
                                        users: newValue.map((customer: any)=> customer.id)
                                    })
                                }}
                                inputValue={userSearchName}
                                onInputChange={(event, newInputValue,) => {
                                    setUserSearchName(newInputValue);
                                }}
                                size="small"
                                renderInput={(params) =>
                                    <TextField {...params} placeholder="ФИО Пользователя" required={notificationInfo.users.length < 1 && !notificationInfo.send_all}
                                />}
                            />
                        </div>
                        <div className='notificationAdd-checkbox w-min flex justify-start items-center'>
                            <label className='w-full flex justify-start items-center gap-3'>
                                <input
                                    checked={notificationInfo.send_all}
                                    onChange={()=>{
                                        setNotificationInfo({
                                            ...notificationInfo,
                                            customers: [],
                                            send_all: !notificationInfo.send_all,
                                        })
                                    }}
                                    type="checkbox"/><span className='whitespace-nowrap'>Отправить всем пользователям</span>
                            </label>
                        </div>
                    </div>
                    <div className='w-full flex gap-10 mb-5'>
                        <TextField
                            value={notificationInfo.title}
                            placeholder='Заголовок уведомления'
                            required={true}
                            fullWidth={true}
                            InputProps={{ sx: { height: 40, width: '100%' } }}
                            onChange={(event) => {
                                setNotificationInfo({
                                    ...notificationInfo,
                                    title: event.target.value,
                                })
                            }}
                        />
                    </div>
                    <div className='w-full h-48'>
                        <ReactQuill theme="snow"
                                    style={{width: '100%', height: '140px'}}
                                    value={notificationInfo.message}
                                    onChange={(value)=>{
                                        setNotificationInfo({
                                            ...notificationInfo,
                                            message:value,
                                        })
                                    }}
                                    modules={modules}
                                    formats={formats}
                        />
                    </div>
                    <div className='flex gap-3'>
                        <button disabled={validation.authRequestIsSent} type='submit' className='submit-button_blue h-10'>
                            {validation.authRequestIsSent
                                ?<div style={{width: '30px', height: '30px'}} className="loader"></div>
                                : <span>Отправить</span>
                            }
                        </button>
                        <Link to='/notifications'  className='submit-button_white h-10'>
                            <span> Отменить</span>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NotificationAdd;

