import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {CreateCleanSearchParams} from "../helpers/helpers";
import {createSearchParams} from "react-router-dom";

export const StaffService = {
    GetCountries() {
        return useAsync(async () => {
            return await $axios.get('/admin/staff/countries/')
        }, [])
    },
    GetPositions() {
        return useAsync(async () => {
            return await $axios.get('/roles/')
        }, [])
    },
    async GetStaff(id: string | undefined) {
        return await $axios.get('/admin/staff/' + `${id}`)
    },
    GetStaffList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/staff/' + CreateCleanSearchParams(searchParamsObj));
        }, [`?${createSearchParams(searchParamsObj)}`])
    },
    async StaffCreate(
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        confirm_password: string,
        country: string,
        group: string,
    ) {
        return await $axios.post('/admin/staff/', {
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            confirm_password: confirm_password,
            country: parseInt(country),
            group: parseInt(group),
        })
    },
    async DeleteStaff(id: string) {
        return await $axios.delete('/admin/staff/' + `${id}/`)
    },
    async StaffUpdate(
        id: string,
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        confirm_password: string,
        country: string,
        group: string,
    ) {
        return await $axios.put('/admin/staff/' + `${id}/`, {
            id: parseInt(id),
            user: {
                first_name: first_name,
                last_name: last_name,
                email: email,
                password: password,
                confirm_password: confirm_password,
                group: parseInt(group),
            },
            country: parseInt(country),
        })
    }
}