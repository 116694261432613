import {$axios} from "../http";

export const AuthService = {
    async GetToken(email: string, password: string) {
        return await $axios.post('/admin/auth/token/', {email: email, password: password})
    },
    async GetPersonalInfo() {
        return await $axios.get('/admin/personal/info/')
    }

}