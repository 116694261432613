import React, {useEffect, useState} from "react";
import {CustomInput, PhotoAddIcon} from "../helpers/common";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {InputCheckForNumbers} from "../helpers/helpers";
import moment from "moment";
import {AnalyticsService} from "../services/AnalyticsService";
import {CurrencyService} from "../services/CurrencyService";
import {TariffService} from "../services/TariffService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Home() {
    const [filterValue, setFilterValue] = React.useState<any>({
        start_created_at: moment(new Date().setMonth(new Date().getMonth()-1)).format('YYYY-MM-DD').toString(),
        end_created_at: moment(new Date().toJSON()).format('YYYY-MM-DD').toString(),
        flag: 'D'
    })
    useEffect(()=>{
        let x = moment(filterValue.end_created_at);
        let y = moment(filterValue.start_created_at)

        let diffInDays = x.diff(y, 'days');

        if(diffInDays < 28){
            setFilterValue({
                ...filterValue,
                flag: 'D'
            })
        }
        if(diffInDays > 28 && diffInDays < 365){
            setFilterValue({
                ...filterValue,
                flag: 'M'
            })
        }
        if(diffInDays > 365){
            setFilterValue({
                ...filterValue,
                flag: 'Y'
            })
        }
    },[filterValue.start_created_at, filterValue.end_created_at])

    const [switchChart, setSwitchChart] = useState({
        chart: 'users'
    })
    const [total_discount, setTotalDiscount] = useState('0')
    const [statistics, setStatistics] = useState({
        users:{
            count: '0',
            data: []
        },
        orders: {
            count: '0',
            data: []
        },
        boxOffice: {
            count: '0',
            data: []
        }
    })

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Статистика',
            },
        },
    };
    const [labels, setLabels] = useState([])

    useEffect(()=>{
        AnalyticsService.GetAnalytics(filterValue.start_created_at, filterValue.end_created_at, filterValue.flag).then((res)=>{
            setLabels(res.data.labels.map((label: any)=>
                moment(label).format(`${filterValue.flag === 'Y' ? 'YYYY' : filterValue.flag === 'M' ? 'MM' : 'DD'}`).toString()
            ))
            setTotalDiscount(`${res.data.total_discount}`)
            setStatistics({
                ...statistics,
                users:{
                    count: res.data.total_users_count,
                    data: res.data.chart_data_users.map((users: any)=> users)
                },
                orders: {
                    count: res.data.orders_count,
                    data: res.data.chart_data_orders.map((orders: any)=> orders)
                },
                boxOffice: {
                    count: res.data.total_cash_amount,
                    data: res.data.chart_data_cash.map((cash: any)=> cash)
                }
            })
        })
    }, [filterValue])

    const data = {
        labels,
        datasets: [
            {
                label: `${switchChart.chart === 'users' ? 'Пользователи' : switchChart.chart === 'orders' ? 'Посылки' : 'Касса'}`,
                data: labels.map((label, index) => statistics[switchChart.chart as keyof typeof statistics].data[index]),
                borderColor: 'rgba(0, 97, 194, 0.9)',
                backgroundColor: 'rgba(0, 97, 194, 0.5)',
            },
        ],
    };



    const [tariffValidation, setTariffValidation] = useState({
        requestIsSend: false,
        modal_opened: false,
        updateRequest: false,
    })
    const tariff = TariffService.GetTariff(tariffValidation.updateRequest)
    const [tariffValues, setTariffValues] = useState({
        id: '',
        address1: '',
        address2: '',
        country: '',
        city: '',
        zip: '',
        phone: '',
        prefix: '',
        delivery_period: '',
        tariff: '0',
        tariff_vip: '0',
    })

    useEffect(()=>{
        if(!tariff.loading && !tariff.error){
            setTariffValues({
                id: tariff.result?.data.id,
                address1: tariff.result?.data.address1,
                address2: tariff.result?.data.address2,
                country: tariff.result?.data.country,
                city: tariff.result?.data.city,
                zip: tariff.result?.data.zip,
                phone: tariff.result?.data.phone,
                prefix: tariff.result?.data.prefix,
                delivery_period: tariff.result?.data.delivery_period,
                tariff: tariff.result?.data.tariff,
                tariff_vip: tariff.result?.data.tariff_vip,
            })
        }
    }, [tariff.loading])

    const submitTariffChange = ()=>{
        setTariffValidation({
            ...tariffValidation,
            requestIsSend: true,
        })
        TariffService.UpdateTariff(tariffValues).then(()=>{
            setTariffValidation({
                ...tariffValidation,
                updateRequest: !tariffValidation.updateRequest,
                requestIsSend: false,
                modal_opened: false,
            })
        })
    }

    const [currencyValidation, setCurrencyValidation] = useState({
        requestIsSend: false,
        modal_opened: false,
        updateRequest: false,
    })
    const [usd, setUsd] = useState({
        id: '',
        name: '',
        value: '0',
        code: 'USD'
    })
    const [cny, setCny] = useState({
        id: '',
        name: '',
        value: '0',
        code: 'CNY'
    })
    const submitCurrencyChange = ()=>{
        setCurrencyValidation({
            ...currencyValidation,
            requestIsSend: true,
        })
        CurrencyService.UpdateCurrency(cny).then(()=>{
            setCurrencyValidation({
                ...currencyValidation,
                updateRequest: !currencyValidation.updateRequest,
                requestIsSend: false,
                modal_opened: false,
            })
        })
        CurrencyService.UpdateCurrency(usd).then(()=>{
            setCurrencyValidation({
                ...currencyValidation,
                updateRequest: !currencyValidation.updateRequest,
                requestIsSend: false,
                modal_opened: false,
            })
        })
    }
    const currencies = CurrencyService.GetCurrencies(currencyValidation.updateRequest)
    useEffect(()=>{
        if(!currencies.loading && !currencies.error){
            for (let i = 0; i < currencies.result?.data.length; i++){
                if(currencies.result?.data[i].code === 'USD'){
                    setUsd({
                        ...usd,
                        id: currencies.result?.data[i].id,
                        name: currencies.result?.data[i].name,
                        value: currencies.result?.data[i].value,
                        code: currencies.result?.data[i].code
                    })
                }
                if(currencies.result?.data[i].code === 'CNY'){
                    setCny({
                        ...cny,
                        id: currencies.result?.data[i].id,
                        name: currencies.result?.data[i].name,
                        value: currencies.result?.data[i].value,
                        code: currencies.result?.data[i].code
                    })
                }
            }
        }
    },[currencies.loading])
    return(
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-col justify-start items-center gap-7 mb-6'>
                    <div className='bg-white shadow-default rounded-10px w-full pt-5 pb-8 px-5 flex flex-col justify-start items-center'>
                        <div className='flex w-full flex-row justify-between items-center gap-7 mb-5'>
                            <h1 className='page__title'>Главная</h1>
                            <div className='flex items-center gap-7'>
                                <CustomInput
                                    className='filter-input text-input_with-border'
                                    type='date'
                                    placeholder='Начало даты'
                                    value={filterValue.start_created_at}
                                    onChange={(event)=>{setFilterValue({
                                        ...filterValue,
                                        start_created_at: event.target.value,
                                        page: 1,
                                    })}}
                                />
                                <CustomInput
                                    className='filter-input text-input_with-border'
                                    type='date'
                                    placeholder='Конец даты'
                                    value={filterValue.end_created_at}
                                    onChange={(event)=>{setFilterValue({
                                        ...filterValue,
                                        end_created_at: event.target.value,
                                        page: 1,
                                    })}}
                                />
                            </div>
                        </div>
                        <div className='statistic__buttons'>
                            <div className={`statistic__button ${switchChart.chart === 'users' ? 'statistic__button_selected' : ''}`}
                                 onClick={()=>{setSwitchChart({...switchChart, chart: 'users'})}}
                            >
                                <p>Общее кол-во пользователей</p>
                                <span>{statistics.users.count}</span>
                            </div>
                            <div className={`statistic__button ${switchChart.chart === 'orders' ? 'statistic__button_selected' : ''}`}
                                 onClick={()=>{setSwitchChart({...switchChart, chart: 'orders'})}}
                            >
                                <p>Общее кол-во посылок</p>
                                <span>{statistics.orders.count}</span>
                            </div>
                            <div className={`statistic__button_discount statistic__button ${switchChart.chart === 'boxOffice' ? 'statistic__button_selected' : ''}`}
                                 onClick={()=>{setSwitchChart({...switchChart, chart: 'boxOffice'})}}
                            >
                                <p>Общая касса (сом)</p>
                                <span>{statistics.boxOffice.count}</span>
                                <div>Общая скидка: {total_discount}</div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white shadow-default rounded-10px w-full p-5 '>
                        <Line options={options} data={data} />
                    </div>
                    <div className='w-full flex gap-5 justify-start items-start'>
                        <div className='bg-white shadow-default rounded-10px w-full p-5 flex flex-col justify-start items-center'>
                            <h1 className='page__title w-full text-start mb-7'>Курс валют</h1>
                            <div className='currencyInfo w-full gap-7 mb-7 flex'>
                                <div className='currencyInfo__item'>
                                    <span>$ USD</span>
                                    <p>{usd.value} <span>сом</span></p>
                                </div>
                                <div className='currencyInfo__item'>
                                    <span>¥ CNY</span>
                                    <p>{cny.value} <span>сом</span></p>
                                </div>
                            </div>
                            <div className='submit-button_white h-10'
                                 onClick={()=>{
                                     setCurrencyValidation({
                                         ...currencyValidation,
                                         modal_opened: true,
                                     })
                                 }}
                            >
                                Редактировать
                            </div>
                        </div>
                        <div className='bg-white shadow-default rounded-10px w-full p-5 flex flex-col justify-start items-center'>
                            <h1 className='page__title w-full text-start mb-7'>Тарифы</h1>
                            <div className='tariffInfo w-full gap-7 mb-7 flex'>
                                <div className='currencyInfo__item'>
                                    <span>1 kg</span>
                                    <p>{tariffValues.tariff} <span>$</span></p>
                                </div>
                                <div className='currencyInfo__item currencyInfo__item_vip'>
                                    <span>1 kg</span>
                                    <p>{tariffValues.tariff_vip} <span>$</span></p>
                                </div>
                            </div>

                            <div className='submit-button_white h-10'
                                 onClick={()=>{
                                     setTariffValidation({
                                         ...tariffValidation,
                                         modal_opened: true,
                                     })
                                 }}
                            >
                                Редактировать
                            </div>
                        </div>
                    </div>
                    {tariffValidation.modal_opened &&
                        <div onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setTariffValidation({
                                ...tariffValidation,
                                requestIsSend: false,
                                modal_opened: false,
                            })
                        }}
                             className='personal-password-edit__modal fixed top-0 left-0 z-999 w-full py-8 h-full flex justify-center items-center bg-white-opacity'
                        >
                            <form onSubmit={(e) => {e.preventDefault(); submitTariffChange()}}
                                  onClick={(e) => {e.stopPropagation()}}
                                  style={{maxWidth: '620px', width: '100%'}}
                                  className='relative max-h-full flex flex-col bg-white justify-start items-center px-14 py-8 rounded-10px shadow-default'
                            >
                                <div onClick={()=>{
                                    setTariffValidation({
                                        ...tariffValidation,
                                        requestIsSend: false,
                                        modal_opened: false,
                                    })
                                }}
                                     className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                                     style={{width: '30px', height: '30px'}}>
                                    <PhotoAddIcon/>
                                </div>
                                <h1 className='mb-7'>
                                    Редактирование тарифа
                                </h1>
                                <div className='w-full overflow-y-auto'>
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Address 1:'
                                        required={false}
                                        label='Address 1:'
                                        value={tariffValues.address1}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            address1: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Address 2:'
                                        required={false}
                                        label='Address 2:'
                                        value={tariffValues.address2}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            address2: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Country:'
                                        required={false}
                                        readOnly={true}
                                        label='Country:'
                                        value={tariffValues.country}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            country: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='City:'
                                        required={false}
                                        label='City:'
                                        value={tariffValues.city}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            city: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Zip Code:'
                                        required={false}
                                        label='Zip Code:'
                                        value={tariffValues.zip}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            zip: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Phone Number:'
                                        required={false}
                                        label='Phone Number:'
                                        value={tariffValues.phone}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            phone: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Префикс:'
                                        required={false}
                                        label='Префикс:'
                                        value={tariffValues.prefix}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            prefix: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Время доставки:'
                                        required={false}
                                        label='Время доставки:'
                                        value={tariffValues.delivery_period}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            delivery_period: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Тариф:'
                                        required={false}
                                        label='Тариф:'
                                        value={tariffValues.tariff}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            tariff: event.target.value,
                                        })}}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder='Особый тариф:'
                                        required={false}
                                        label='Особый тариф:'
                                        value={tariffValues.tariff_vip}
                                        onChange={(event)=>{setTariffValues({
                                            ...tariffValues,
                                            tariff_vip: event.target.value,
                                        })}}
                                    />
                                </div>

                                <button type='submit' disabled={tariffValidation.requestIsSend} className='flex-shrink-0 submit-button_blue h-10 mt-5'>
                                    {tariffValidation.requestIsSend
                                        ? <div className="loader"></div>
                                        : <span>Редактировать</span>
                                    }
                                </button>
                            </form>
                        </div>
                    }
                    {currencyValidation.modal_opened &&
                        <div onClick={(e) => {e.preventDefault();e.stopPropagation()
                            setCurrencyValidation({
                                ...currencyValidation,
                                requestIsSend: false,
                                modal_opened: false,
                                updateRequest: !currencyValidation.updateRequest
                            })
                        }}
                             className='personal-password-edit__modal fixed top-0 left-0 z-999 w-full py-8 h-full flex justify-center items-center bg-white-opacity'
                        >
                            <form onSubmit={(e) => {e.preventDefault(); submitCurrencyChange()}}
                                  onClick={(e) => {e.stopPropagation()}}
                                  style={{maxWidth: '620px', width: '100%'}}
                                  className='relative max-h-full flex flex-col bg-white justify-start items-center px-14 py-8 rounded-10px shadow-default'
                            >
                                <div onClick={()=>{
                                    setCurrencyValidation({
                                        ...currencyValidation,
                                        requestIsSend: false,
                                        modal_opened: false,
                                        updateRequest: !currencyValidation.updateRequest
                                    })
                                }}
                                     className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                                     style={{width: '30px', height: '30px'}}>
                                    <PhotoAddIcon/>
                                </div>
                                <h1 className='mb-7'>
                                    Редактирование курса
                                </h1>
                                <div className='w-full overflow-y-auto'>
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder={usd.code}
                                        required={false}
                                        label={usd.code}
                                        value={usd.value}
                                        onChange={(event)=>{
                                            setUsd({
                                                ...usd,
                                                value: InputCheckForNumbers(event.target.value),
                                            })
                                        }}
                                    />
                                    <CustomInput
                                        className='text-input text-input_with-border'
                                        type='text'
                                        placeholder={cny.code}
                                        required={false}
                                        label={cny.code}
                                        value={cny.value}
                                        onChange={(event)=>{
                                            setCny({
                                                ...cny,
                                                value: InputCheckForNumbers(event.target.value),
                                            })
                                        }}
                                    />
                                </div>

                                <button type='submit' disabled={currencyValidation.requestIsSend} className='flex-shrink-0 submit-button_blue h-10 mt-5'>
                                    {currencyValidation.requestIsSend
                                        ? <div className="loader"></div>
                                        : <span>Редактировать</span>
                                    }
                                </button>
                            </form>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Home