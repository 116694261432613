import React, {useState} from "react";
import {CustomInput, PasswordHiddenIcon, PasswordVisibleIcon} from "../helpers/common";
import {setCookie} from "typescript-cookie";
import {AuthService} from "../services/AuthService";
import jwtDecode from "jwt-decode";

function AuthPage(){
    const [authValidation, setAuthValidation] = useState<any>({
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        passwordVisible: false,
        authRequestIsSent: false,
    })
    const [authValues, setAuthValues] = useState<any>({
        email: '',
        password: '',
    })
    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setAuthValidation({
            ...authValidation,
            authRequestIsSent: true,
        })
        AuthService.GetToken(authValues.email, authValues.password).then((res)=>{
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = Math.ceil(accessDifferenceInSeconds / (60 * 60 * 24));
            const refreshDifferenceInDays = Math.ceil(refreshDifferenceInSeconds / (60 * 60 * 24));
            setCookie('goldMark_admin_access_token', res.data.access, {expires: accessDifferenceInDays})
            setCookie('goldMark_admin_refresh_token', res.data.refresh, {expires: refreshDifferenceInDays})
            setCookie('goldMark_group', res.data.group, {expires: refreshDifferenceInDays})
            setTimeout(()=>{
                window.location.reload()
            },1000)
        }).catch((err)=>{
            if(err.response.status === 401){
                setAuthValidation({
                    ...authValidation,
                    emailCheck: true,
                    emailHelperText: err.response.data.detail,
                    passwordCheck: true,
                    passwordHelperText: err.response.data.detail,
                    authRequestIsSent: false,
                });
            }
            if(err.response.status === 404){
                setAuthValidation({
                    ...authValidation,
                    emailCheck: true,
                    emailHelperText: err.response.statusText,
                    passwordCheck: true,
                    passwordHelperText: err.response.statusText,
                    authRequestIsSent: false,
                });
            }
        })
    }
    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen'>
            <form onSubmit={handleSubmit} className='flex flex-col justify-start items-center py-60px px-50px bg-light-blue rounded-10px shadow-default max-w-auth-width w-full'>
                <h1 className="auth__title">Авторизация</h1>
                <CustomInput
                    className='text-input'
                    type='email'
                    placeholder='Email'
                    required={true}
                    label=''
                    value={authValues.email}
                    onChange={(event)=>{setAuthValues({
                        ...authValues,
                        email: event.target.value,
                    })}}
                    helperText={authValidation.emailHelperText}
                    error={authValidation.emailCheck}
                />
                <CustomInput
                    className='text-input text-input_with-props'
                    type={authValidation.passwordVisible ? 'text' : 'password'}
                    placeholder='Пароль'
                    label=''
                    value={authValues.password}
                    helperText={authValidation.passwordHelperText}
                    required={true}
                    onChange={(event)=>{setAuthValues({
                        ...authValues,
                        password: event.target.value,
                    })}}
                    error={authValidation.passwordCheck}
                    inputProps={
                        <div className='text-input_icon_button' onClick={()=>{
                            setAuthValidation({
                                ...authValidation,
                                passwordVisible: !authValidation.passwordVisible,
                            })
                        }}>
                            {authValidation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                        </div>
                    }
                />
                <button disabled={authValidation.authRequestIsSent} type='submit' className='submit-button_blue h-12 w-full mb-4'>
                    {authValidation.authRequestIsSent
                        ?<div className="loader"></div>
                        :'Войти'
                    }
                </button>
            </form>
        </div>
    );
}

export default AuthPage;