import React, {useEffect, useState} from 'react';
import {Autocomplete, TextField} from "@mui/material";
import {CustomInput, CustomSelect} from "../helpers/common";
import {InputCheckForNumbers} from "../helpers/helpers";
import {Link, useNavigate} from "react-router-dom";
import {BoxOfficeService} from "../services/BoxOfficeService";
import {ClientsService} from "../services/ClientsService";
import {CurrencyService} from "../services/CurrencyService";
import {OrdersService} from "../services/OrdersService";

function RansomAdd() {
    const navigate = useNavigate()
    const [validation, setValidation] = useState({
        requestIsSend: false,
        tracking_numberCheck: false,
        tracking_numberHelperText: '',
    })
    const [ransomInfo, setRansomInfo] = useState({
        ransom_order:{
            tracking_number: '',
            title: '',
            comment: '',
            quantity: '0',
            price: '0',
            order_total_price: '0',
        },
        product_link: '',
        code: '',
        user_id: '',
        full_name: '',
        payment_type: '',
        currency_value: '0',
        currency: '',
        withdrawal_amount: '0',
        commission: '0',
    })
    const currencies = CurrencyService.GetCurrencies()
    const payments = BoxOfficeService.GetPayments()

    const handleSubmit = () =>{
        setValidation({
            ...validation,
            requestIsSend: true,
        })
        OrdersService.CreateRansom(ransomInfo).then(()=>{
            navigate('/ransom')
        }).catch((err)=>{
            if(err.response.data.ransom_order.tracking_number){
                setValidation({
                    ...validation,
                    tracking_numberCheck: true,
                    tracking_numberHelperText: err.response.data.ransom_order.tracking_number[0],
                    requestIsSend: false,
                })
            }
        })
    }

    //get customer by code
    const customer = ClientsService.GetCustomerBySearch({search: ransomInfo.code})
    useEffect(()=>{
        if(!customer.loading && !customer.error){
            if(customer.result?.data.length > 0){
                setRansomInfo({
                    ...ransomInfo,
                    user_id: customer.result?.data[0].id,
                    full_name: customer.result?.data[0].full_name
                })
            }
            else {
                setRansomInfo({
                    ...ransomInfo,
                    user_id: '',
                    full_name: '',
                })
            }
        }
    },[customer.loading])

    useEffect(()=>{
        setRansomInfo({
            ...ransomInfo,
            ransom_order:{
                ...ransomInfo.ransom_order,
                order_total_price: `${(parseInt(ransomInfo.ransom_order.price) * parseInt(ransomInfo.ransom_order.quantity) + parseInt(ransomInfo.commission)) * parseInt(ransomInfo.currency_value)}`
           },
            withdrawal_amount: `${(parseInt(ransomInfo.ransom_order.price) * parseInt(ransomInfo.ransom_order.quantity) + parseInt(ransomInfo.commission)) * parseInt(ransomInfo.currency_value)}`
        })
    },[ransomInfo.ransom_order.price, ransomInfo.ransom_order.quantity, ransomInfo.commission, ransomInfo.currency_value])

    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-start items-center gap-7 mb-6'>
                    <h1 className='page__title'>Добавление выкупа</h1>
                </div>
                <div className='rounded-10px shadow-default bg-white overflow-hidden w-full flex flex-col justify-start items-center'>
                    <div className='form-add__header bg-form-header w-full px-6 py-3 flex justify-between items-center'>
                        <div className='flex justify-start items-center gap-8'>
                            <p>
                                Посылка
                            </p>
                        </div>
                    </div>
                    <form onSubmit={(event)=>{
                        event.preventDefault()
                        handleSubmit()
                    }} className="form-add__body bg-white w-full px-6 py-6 flex flex-col justify-start items-center">
                        <div className='w-full flex gap-6'>
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                placeholder='ФИО Пользователя:'
                                label='ФИО Пользователя:'
                                value={ransomInfo.full_name}
                                readOnly={true}
                                required={true}
                            />
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                required={true}
                                placeholder='Код Пользователя:'
                                label='Код Пользователя:'
                                value={ransomInfo.code}
                                onChange={(event)=>{
                                    setRansomInfo({
                                        ...ransomInfo,
                                        code: event.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className='w-full flex gap-6'>
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                placeholder='Курс:'
                                required={true}
                                label='Курс:'
                                value={ransomInfo.currency_value}
                                readOnly={true}
                            />
                            <CustomSelect
                                className='default-select default-select_40px default-select_with-border'
                                placeholder='Валюта:'
                                label='Валюта:'
                                required={true}
                                value={ransomInfo.currency}
                                onChange={(event)=>{
                                    let currencyValue = '0'
                                    if(!currencies.loading && !currencies.error){
                                        for(let i=0; i<currencies.result?.data.length; i++){
                                            if(currencies.result?.data[i].id === parseInt(event.target.value)){
                                                currencyValue = currencies.result?.data[i].value
                                            }
                                        }
                                    }
                                    setRansomInfo({
                                        ...ransomInfo,
                                        currency: event.target.value,
                                        currency_value: currencyValue,
                                    })
                                }}
                            >
                                <option value=""></option>
                                {!currencies.loading && !currencies.error && currencies.result?.data.map((currency: any, i:number)=>(
                                    <option key={i} value={currency.id}>{currency.name}</option>
                                ))}
                            </CustomSelect>
                        </div>
                        <div className='w-full flex gap-6'>
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                placeholder='Товар:'
                                required={true}
                                label='Товар:'
                                value={ransomInfo.ransom_order.title}
                                onChange={(event)=>{
                                    setRansomInfo({
                                        ...ransomInfo,
                                        ransom_order: {
                                            ...ransomInfo.ransom_order,
                                            title: event.target.value
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                placeholder='Ссылка на товар:'
                                required={true}
                                label='Ссылка на товар:'
                                value={ransomInfo.product_link}
                                onChange={(event)=>{
                                    setRansomInfo({
                                        ...ransomInfo,
                                        product_link: event.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className='w-full flex gap-6 items-end'>
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='number'
                                placeholder='Кол-во:'
                                required={true}
                                label='Кол-во:'
                                value={ransomInfo.ransom_order.quantity}
                                onChange={(event)=>{
                                    setRansomInfo({
                                        ...ransomInfo,
                                        ransom_order: {
                                            ...ransomInfo.ransom_order,
                                            quantity: InputCheckForNumbers(event.target.value)
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='number'
                                placeholder='Стоимость:'
                                required={true}
                                label='Стоимость:'
                                value={ransomInfo.ransom_order.price}
                                onChange={(event)=>{
                                    setRansomInfo({
                                        ...ransomInfo,
                                        ransom_order: {
                                            ...ransomInfo.ransom_order,
                                            price: InputCheckForNumbers(event.target.value)
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='number'
                                placeholder='Комиссия:'
                                required={true}
                                label='Комиссия:'
                                value={ransomInfo.commission}
                                onChange={(event)=>{
                                    setRansomInfo({
                                        ...ransomInfo,
                                        commission: InputCheckForNumbers(event.target.value)
                                    })
                                }}
                            />
                            <p className='w-full whitespace-nowrap mb-4'>Итог: {ransomInfo.ransom_order.order_total_price} сом</p>
                        </div>
                        <CustomSelect
                            className='default-select default-select_40px default-select_with-border'
                            placeholder='Вид оплаты:'
                            label='Вид оплаты:'
                            required={true}
                            value={ransomInfo.payment_type}
                            onChange={(event)=>{
                                setRansomInfo({
                                    ...ransomInfo,
                                    payment_type: event.target.value,
                                })
                            }}
                        >
                            <option value=""></option>
                            {!payments.loading && !payments.error && payments.result?.data.map((payment: any, i:number)=>(
                                <option key={i} value={payment.id}>{payment.name}</option>
                            ))}
                        </CustomSelect>
                        <div className='w-full flex gap-6'>
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                placeholder='Итого к оплате:'
                                required={true}
                                label='Итого к оплате:'
                                value={`${ransomInfo.ransom_order.order_total_price} сом`}
                                readOnly={true}
                            />
                            <CustomInput
                                className='text-input text-input_40px text-input_with-border'
                                type='text'
                                placeholder='Снятие денег:'
                                required={true}
                                label='Снятие денег:'
                                value={`${ransomInfo.withdrawal_amount} сом`}
                                readOnly={true}
                            />
                        </div>
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Трек код:'
                            required={true}
                            label='Трек код:'
                            error={validation.tracking_numberCheck}
                            helperText={validation.tracking_numberHelperText}
                            value={ransomInfo.ransom_order.tracking_number}
                            onChange={(event)=>{
                                setRansomInfo({
                                    ...ransomInfo,
                                    ransom_order: {
                                        ...ransomInfo.ransom_order,
                                        tracking_number: event.target.value
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Комментарий:'
                            label='Комментарий:'
                            value={ransomInfo.ransom_order.comment}
                            onChange={(event)=>{
                                setRansomInfo({
                                    ...ransomInfo,
                                    ransom_order: {
                                        ...ransomInfo.ransom_order,
                                        comment: event.target.value
                                    }
                                })
                            }}
                        />
                        <div className='flex gap-3'>
                            <button disabled={validation.requestIsSend || ransomInfo.ransom_order.order_total_price !== ransomInfo.withdrawal_amount} type='submit' className='submit-button_blue h-10'>
                                {validation.requestIsSend
                                    ?<div style={{width: '30px', height: '30px'}} className="loader"></div>
                                    : <span>Добавить</span>
                                }
                            </button>
                            <Link to='/ransom'  className='submit-button_white h-10'>
                                <span> Отменить</span>
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default RansomAdd;

