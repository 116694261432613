import React, {useEffect, useState} from 'react';
import {
    CustomPagination,
    CustomTable,
} from "../helpers/common";
import {useParams} from "react-router-dom";
import {OrdersService} from "../services/OrdersService";
import {Pagination} from "@mui/material";

function TotalWeightOfOrdersGroups() {
    const {id} = useParams()
    const [filterValue, setFilterValue] = React.useState<any>({
        page: 1,
        count: 1,
    })
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID', width: '80px', hide: true},
        { field: 'user_id', headerName: 'Код клиента', width: '80px'},
        { field: 'full_name', headerName: 'ФИО'},
        { field: 'total_count', headerName: 'Общее количество', width: '80px'},
        { field: 'total_weight', headerName: 'Общий вес', width: '80px'},
        { field: 'total_sum', headerName: 'Сумма'},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])

    const shipmentHistoryGroupList = OrdersService.GetTotalWeightOfOrdersGroups(id, filterValue)
    useEffect(()=>{
        if(!shipmentHistoryGroupList.loading && !shipmentHistoryGroupList.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: shipmentHistoryGroupList.result?.data.total_pages,
                page: shipmentHistoryGroupList.result?.data.current_page,
            }))
            const arr: any[] = []
            for (let i=0;i< shipmentHistoryGroupList.result?.data.results.length;i++){
                arr[i] = {
                    id: shipmentHistoryGroupList.result?.data.results[i].id,
                    user_id: shipmentHistoryGroupList.result?.data.results[i].user.code,
                    full_name: shipmentHistoryGroupList.result?.data.results[i].user.full_name,
                    total_count: shipmentHistoryGroupList.result?.data.results[i].orders_count,
                    total_weight: `${shipmentHistoryGroupList.result?.data.results[i].total_weight} кг`,
                    total_sum: `${shipmentHistoryGroupList.result?.data.results[i].total_sum} сом`,
                }
            }
            setMainTableData(arr)
        }
    },[shipmentHistoryGroupList.loading])
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Прибывшие посылки</h1>
                </div>

                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={shipmentHistoryGroupList.loading}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default TotalWeightOfOrdersGroups;

