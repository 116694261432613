import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomPagination,
    CustomTable,
    FilterSearchIcon,PhotoAddIcon
} from "../helpers/common";
import {useNavigate} from "react-router-dom";
import {ClientsService} from "../services/ClientsService";
import {OrdersService} from "../services/OrdersService";
import {Pagination} from "@mui/material";

function LostOrders() {
    const navigate = useNavigate()
    const [filterValue, setFilterValue] = React.useState<any>({
        search: '',
        count: 1,
        page: 1,
        update_request: false,
    })
    const [validation, setValidation] = useState({
        modal_opened: false,
        requestIsSend: false,
    })
    const [values, setValues] = useState({
        full_name:'',
        user_id: '',
    })
    const lostOrders = OrdersService.GetLostOrders(filterValue)
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID посылки', hide: true},
        { field: 'username', headerName: 'ФИО клиента' },
        { field: 'tracking_number', headerName: 'Трек номер', width: '100px'},
        { field: 'weight', headerName: 'Вес', width: '100px'},
        { field: 'price', headerName: 'Цена'},
    ];
    const [selectedRowsId, setSelectedRowsId] = useState<any>([])
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(()=>{
        if(!lostOrders.loading && !lostOrders.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: lostOrders.result?.data.total_pages,
                page: lostOrders.result?.data.current_page,
            }))
            const data = []
            for(let i=0; i<lostOrders.result?.data.results.length;i++){
                data[i] = {
                    id: lostOrders.result?.data.results[i].id,
                    username: lostOrders.result?.data.results[i].attribute,
                    tracking_number: lostOrders.result?.data.results[i].tracking_number,
                    weight: lostOrders.result?.data.results[i].weight,
                    price: lostOrders.result?.data.results[i].price,
                }
            }
            setMainTableData(data)
        }
    },[lostOrders.loading])
    const customersList = ClientsService.GetCustomersListBySearch(values.user_id)

    useEffect(()=>{
        if(!customersList.loading && !customersList.error){
            if(customersList.result?.data.length > 0){
                setValues((prevState)=>({
                    ...prevState,
                    full_name: customersList.result?.data[0].full_name
                }))
            }
            else{
                setValues((prevState)=>({
                    ...prevState,
                    full_name: ''
                }))
            }
        }
    },[customersList.loading, customersList.error])

    const submitChanges = () =>{
        setValidation({
            ...validation,
            requestIsSend: true,
        })
        OrdersService.AssignLostOrder(selectedRowsId, values.user_id).then(()=>{
            setValidation({
                ...validation,
                modal_opened: false,
                requestIsSend: false,
            })
            setSelectedRowsId([])
            setFilterValue({
                ...filterValue,
                page: 1,
                update_request: !filterValue.update_request,
            })
        }).catch((err)=>{
            setValidation({
                ...validation,
                requestIsSend: false,
            })
        })
    }
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Неизвестные посылки</h1>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='Введите номер трека или посылки'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* таблица */}
                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    selectedRowsId={selectedRowsId}
                    checkboxSelection={true}
                    loading={lostOrders.loading}
                    onSelectionModelChange={(selectionModel: [])=>setSelectedRowsId(selectionModel)}
                    onRowDoubleClick={(params: any)=>{navigate(`/orderView/${params.id}`)}}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <div className='w-full flex justify-between items-center mt-2.5 mr-2.5'>
                                <div>

                                </div>
                                <div className='flex justify-center items-center gap-5'>
                                    {selectedRowsId.length > 0 &&
                                        <span className='selected-orders-count'>Выбрано {selectedRowsId.length}</span>
                                    }
                                    <button disabled={selectedRowsId.length === 0}
                                            className='submit-button_blue submit-button_blue_small h-7'
                                            onClick={()=>{setValidation({...validation,modal_opened: true})}}
                                    >
                                        Привязать к
                                    </button>
                                </div>
                            </div>
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
            {validation.modal_opened &&
                <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setValidation({...validation,modal_opened: false})
                }}
                     className='personal-password-edit__modal fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity'
                >
                    <form onSubmit={(e) => {e.preventDefault(); submitChanges()}}
                          onClick={(e) => {e.stopPropagation()}}
                          style={{maxWidth: '620px', width: '100%'}}
                          className='relative flex flex-col bg-white justify-start items-center px-14 py-8 rounded-10px shadow-default'
                    >
                        <div onClick={()=>{setValidation({...validation, modal_opened: false})}}
                             className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                             style={{width: '30px', height: '30px'}}>
                            <PhotoAddIcon/>
                        </div>
                        <h1 className='mb-7'>Привязка товара</h1>
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='ФИО Пользователя:'
                            label='ФИО Пользователя:'
                            value={values.full_name}
                            readOnly={true}
                            required={true}
                        />
                        <CustomInput
                            className='text-input text-input_40px text-input_with-border'
                            type='text'
                            placeholder='Код Пользователя:'
                            label='Код Пользователя:'
                            value={values.user_id}
                            onChange={(event)=>{
                                setValues({
                                    ...values,
                                    user_id: event.target.value
                                })
                            }}
                        />
                        <button type='submit' disabled={validation.requestIsSend} className='submit-button_blue h-10'>
                            {validation.requestIsSend
                                ? <div className="loader"></div>
                                : <span>Привязать</span>
                            }
                        </button>
                    </form>
                </div>
            }
        </div>
    );
}

export default LostOrders;

