import React, {useEffect, useState} from 'react';
import {
    CustomInput,
    CustomPagination, CustomSelect,
    CustomTable,
    FilterSearchIcon,
    OrderDeleteIcon,
    OrderEditIcon, PasswordHiddenIcon, PasswordVisibleIcon, PhotoAddIcon
} from "../helpers/common";
import moment from "moment";
import {StaffService} from "../services/StaffService";
import {Pagination} from "@mui/material";

function Personal() {
    const [filterValue, setFilterValue] = React.useState<any>({
        search: '',
        page: 1,
        count: 1,
        update_request: false,
    })
    const [staffValidation, setStaffValidation] = useState({
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        modal_opened: false,
        type: '',
        passwordVisible: false,
        requestIsSend: false,
    })
    const [staffValues, setStaffValues] = useState({
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        position: '',
        country: '',
        password: '',
        repeat_password: '',
    })
    const handleDataClear = ()=>{
        setStaffValidation({
            ...staffValidation,
            emailCheck: false,
            emailHelperText: '',
            passwordCheck: false,
            passwordHelperText: '',
            modal_opened: false,
            type: '',
            passwordVisible: false,
            requestIsSend: false,
        })
        setStaffValues({
            ...staffValues,
            id: '',
            first_name: '',
            last_name: '',
            email: '',
            position: '',
            country: '',
            password: '',
            repeat_password: '',
        })
    }
    const staffList = StaffService.GetStaffList(filterValue)
    const mainTableColumns: any[] = [
        { field: 'id', headerName: 'ID сотрудника', hide: true},
        { field: 'date_joined', headerName: 'Дата', width: '90px'},
        { field: 'username', headerName: 'ФИО сотрудника' },
        { field: 'position', headerName: 'Должность', width: '100px'},
        { field: 'country', headerName: 'Страна', width: '100px'},
        { field: 'email', headerName: 'Email'},
        { field: 'status', headerName: 'Статус', width: '100px', renderCell: (params: any) => {
            return(
                <div className="flex justify-start items-center gap-3">
                    {params.status
                        ? <p style={{color: 'green'}}>Активен</p>
                        : <p style={{color: 'red'}}>Не активен</p>
                    }
                </div>
            )}},

        { field: 'edit', headerName: '', width: '80px', renderCell: (params: any) => {
                return (
                    <div className="flex justify-center items-center gap-3">
                        {params.position !== 'Администратор' &&
                            <div className='page-table__icon-button' onClick={()=>{
                                setStaffValidation({
                                    ...staffValidation,
                                    modal_opened: true,
                                    type: 'edit',
                                })
                                setStaffValues({
                                    ...staffValues,
                                    id: params.id
                                })
                            }}>
                                <OrderEditIcon/>
                            </div>
                        }
                        {params.position !== 'Администратор' &&
                            <div className='page-table__icon-button' onClick={()=>{
                                StaffService.DeleteStaff(params.id).then(()=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: 1,
                                        update_request: !filterValue.update_request,
                                    })
                                })
                            }}>
                                <OrderDeleteIcon/>
                            </div>
                        }
                    </div>
                )
            }},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(()=>{
        if(!staffList.loading && !staffList.error){
            setFilterValue((prevState: any)=>({
                ...prevState,
                count: staffList.result?.data.total_pages,
                page: staffList.result?.data.current_page,
            }))
            const data = []
            for(let i=0; i<staffList.result?.data.results.length;i++){
                data[i] = {
                    id: staffList.result?.data.results[i].id,
                    date_joined: (moment(staffList.result?.data.results[i].user.date_joined)).format('DD.MM.YYYY').toString(),
                    username: `${staffList.result?.data.results[i].user.first_name} ${staffList.result?.data.results[i].user.last_name}`,
                    position: (staffList.result?.data.results[i].user.groups[0].name),
                    email: staffList.result?.data.results[i].user.email,
                    status: staffList.result?.data.results[i].user.is_active,
                    country: staffList.result?.data.results[i].country.name
                }
            }
            setMainTableData(data)
        }
    },[staffList.loading])

    const submitStaffCreate = () =>{
        setStaffValidation({
            ...staffValidation,
            requestIsSend: true,
        })
        if(staffValidation.type === 'edit'){
            StaffService.StaffUpdate(
                staffValues.id,
                staffValues.first_name,
                staffValues.last_name,
                staffValues.email,
                staffValues.password,
                staffValues.repeat_password,
                staffValues.country,
                staffValues.position,
            ).then(()=>{
                setFilterValue({
                    ...filterValue,
                    page: 1,
                    update_request: !filterValue.update_request,
                })
                handleDataClear()
            }).catch((err)=>{
                if(err.response.data.email){
                    setStaffValidation({
                        ...staffValidation,
                        emailCheck: true,
                        emailHelperText: err.response.data.email[0],
                        requestIsSend: false,
                    })
                }
                if(err.response.data.password){
                    setStaffValidation({
                        ...staffValidation,
                        passwordCheck: true,
                        passwordHelperText: err.response.data.password[0],
                        requestIsSend: false,
                    })
                }
            })
        }
        if(staffValidation.type === 'add'){
            StaffService.StaffCreate(
                staffValues.first_name,
                staffValues.last_name,
                staffValues.email,
                staffValues.password,
                staffValues.repeat_password,
                staffValues.country,
                staffValues.position,
            ).then(()=>{
                setFilterValue({
                    ...filterValue,
                    page: 1,
                    update_request: !filterValue.update_request,
                })
                handleDataClear()
            }).catch((err)=>{
                if(err.response.data.email){
                    setStaffValidation({
                        ...staffValidation,
                        emailCheck: true,
                        emailHelperText: err.response.data.email[0],
                        requestIsSend: false,
                    })
                }
                if(err.response.data.password){
                    setStaffValidation({
                        ...staffValidation,
                        passwordCheck: true,
                        passwordHelperText: err.response.data.password[0],
                        requestIsSend: false,
                    })
                }
            })
        }
    }
    useEffect(()=>{
        if(staffValidation.type === 'edit' || staffValidation.type === 'view'){
            StaffService.GetStaff(staffValues.id).then((res)=>{
                setStaffValues({
                    ...staffValues,
                    first_name: res.data.user.first_name,
                    last_name: res.data.user.last_name,
                    email: res.data.user.email,
                    position: res.data.user.groups[0].id,
                    country: res.data.country.id,
                })
            })
        }
    },[staffValidation.type])
    const positions = StaffService.GetPositions()
    const countries = StaffService.GetCountries()
    return (
        <div className='componentMainWrapper'>
            <div className='w-full pb-9'>
                <div className='flex w-full flex-row justify-between items-center gap-7 mb-6'>
                    <h1 className='page__title'>Сотрудники</h1>
                    <div className='submit-button_blue h-10'
                         onClick={()=>{setStaffValidation({...staffValidation, modal_opened: true, type: 'add',})}}
                    >
                        Добавить сотрудника
                    </div>
                </div>

                <div className='flex w-full flex-row justify-between items-center mb-6'>
                    <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    </div>
                    <div className='flex w-fit flex-row justify-start items-center'>
                        <CustomInput
                            className='filter-input_search'
                            type='text'
                            placeholder='ФИО сотрудника'
                            value={filterValue.search}
                            onChange={(event)=>{setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                                page: 1,
                            })}}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>

                {/* таблица */}
                <CustomTable
                    columns={mainTableColumns}
                    rows={mainTableData}
                    checkboxSelection={false}
                    loading={staffList.loading}
                    onRowDoubleClick={(params: any)=>{
                        setStaffValidation({
                            ...staffValidation,
                            modal_opened: true,
                            type: 'view',
                        })
                        setStaffValues({
                            ...staffValues,
                            id: params.id
                        })
                    }}
                    footer={
                        <div className="page-table__footer flex flex-col justify-start items-center p-2.5">
                            <Pagination
                                color="primary"
                                count={filterValue.count}
                                page={filterValue.page}
                                onChange={(event,value)=>{
                                    setFilterValue({
                                        ...filterValue,
                                        page: value,
                                    })
                                }}
                            />
                        </div>
                    }
                />
            </div>
            {staffValidation.modal_opened &&
                <div onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleDataClear()
                }}
                     className='personal-password-edit__modal p-20 fixed top-0 left-0 z-999 w-full h-full flex justify-center items-center bg-white-opacity'
                >
                    <form onSubmit={(e) => {e.preventDefault(); submitStaffCreate()}}
                          onClick={(e) => {e.stopPropagation()}}
                          style={{maxWidth: '620px', width: '100%'}}
                          className='h-full relative flex flex-col bg-white justify-start items-center px-20 py-8 rounded-10px shadow-default'
                    >
                        <div onClick={handleDataClear}
                             className='absolute right-6 top-8 rotate-45 cursor-pointer rounded-full flex justify-center items-center hover:bg-icon-button-hover'
                             style={{width: '30px', height: '30px'}}>
                            <PhotoAddIcon/>
                        </div>
                        <h1 className='mb-7'>
                            {staffValidation.type === 'view' && 'Просмотр сотрудника'}
                            {staffValidation.type === 'edit' && 'Изменение сотрудника'}
                            {staffValidation.type === 'add' && 'Добавление сотрудника'}
                        </h1>
                        <div className='w-full flex flex-col overflow-scroll h-full'>
                            <CustomInput
                                className='text-input text-input_with-border'
                                type='text'
                                placeholder='Имя'
                                required={true}
                                readOnly={staffValidation.type === 'view'}
                                label='Имя'
                                value={staffValues.first_name}
                                onChange={(event)=>{setStaffValues({
                                    ...staffValues,
                                    first_name: event.target.value,
                                })}}
                            />
                            <CustomInput
                                className='text-input text-input_with-border'
                                type='text'
                                placeholder='Фамилия'
                                required={true}
                                readOnly={staffValidation.type === 'view'}
                                label='Фамилия'
                                value={staffValues.last_name}
                                onChange={(event)=>{setStaffValues({
                                    ...staffValues,
                                    last_name: event.target.value,
                                })}}
                            />
                            <CustomInput
                                className='text-input text-input_with-border'
                                type='email'
                                placeholder='Email'
                                required={true}
                                readOnly={staffValidation.type === 'view'}
                                label='Email'
                                value={staffValues.email}
                                onChange={(event)=>{setStaffValues({
                                    ...staffValues,
                                    email: event.target.value,
                                })}}
                                helperText={staffValidation.emailHelperText}
                                error={staffValidation.emailCheck}
                            />
                            <CustomSelect
                                className='default-select default-select_with-border'
                                placeholder='Должность'
                                label='Должность'
                                required={true}
                                disabled={staffValidation.type === 'view'}
                                value={staffValues.position}
                                onChange={(event)=>{
                                    setStaffValues({
                                        ...staffValues,
                                        position: event.target.value,
                                    })
                                }}
                            >
                                <option value=""></option>
                                {!positions.loading && !positions.error && positions.result?.data.map((position: any, i:number)=>(
                                    <option key={i} value={position.id}>{position.name}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                className='default-select default-select_with-border'
                                placeholder='Страна'
                                label='Страна'
                                required={true}
                                disabled={staffValidation.type === 'view'}
                                value={staffValues.country}
                                onChange={(event)=>{
                                    setStaffValues({
                                        ...staffValues,
                                        country: event.target.value,
                                    })
                                }}
                            >
                                <option value=""></option>
                                {!countries.loading && !countries.error && countries.result?.data.map((country: any, i:number)=>(
                                    <option key={i} value={country.id}>{country.name}</option>
                                ))}
                            </CustomSelect>
                            <CustomInput
                                className='text-input text-input_with-props text-input_with-border'
                                type={staffValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Пароль'
                                label='Пароль'
                                value={staffValues.password}
                                required={staffValidation.type !== 'edit'}
                                readOnly={staffValidation.type === 'view'}
                                onChange={(event) => {
                                    setStaffValues({
                                        ...staffValues,
                                        password: event.target.value,
                                    })
                                }}
                                helperText={staffValidation.passwordHelperText}
                                error={staffValidation.passwordCheck}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={() => {
                                        setStaffValidation({
                                            ...staffValidation,
                                            passwordVisible: !staffValidation.passwordVisible,
                                        })
                                    }}>
                                        {staffValidation.passwordVisible ? <PasswordHiddenIcon/> :
                                            <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                            <CustomInput
                                className='text-input text-input_with-props text-input_with-border'
                                type={staffValidation.passwordVisible ? 'text' : 'password'}
                                placeholder='Повторите пароль'
                                label='Повторите пароль'
                                value={staffValues.repeat_password}
                                required={staffValidation.type !== 'edit'}
                                readOnly={staffValidation.type === 'view'}
                                onChange={(event) => {
                                    setStaffValues({
                                        ...staffValues,
                                        repeat_password: event.target.value,
                                    })
                                }}
                                helperText={staffValues.password !== staffValues.repeat_password ? 'Пароли не соответствуют!' : ''}
                                error={staffValues.password !== staffValues.repeat_password}
                                inputProps={
                                    <div className='text-input_icon_button' onClick={() => {
                                        setStaffValidation({
                                            ...staffValidation,
                                            passwordVisible: !staffValidation.passwordVisible,
                                        })
                                    }}>
                                        {staffValidation.passwordVisible ? <PasswordHiddenIcon/> :
                                            <PasswordVisibleIcon/>}
                                    </div>
                                }
                            />
                        </div>
                        {staffValidation.type !== 'view' &&
                            <button type='submit' disabled={staffValidation.requestIsSend} className='submit-button_blue h-10'>
                                {staffValidation.requestIsSend
                                    ? <div className="loader"></div>
                                    : staffValidation.type === 'edit'
                                        ? <span>Редактировать</span>
                                        : <span>Добавить</span>
                                }
                            </button>
                        }
                    </form>
                </div>
            }
        </div>
    );
}

export default Personal;

